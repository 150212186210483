/* eslint-disable import/no-unresolved */
import { useEffect } from 'react';

const IndexPage = () => {
  useEffect(() => {
    window.open('https://lin.ee/kAyObmc', '_self');
  });

  return <div>Redirect...</div>;
};

export default IndexPage;
